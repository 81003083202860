import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import MultiItemCarousel from '../MultiItemCarousel';
import HeaderBig from '../text/HeaderBig';
import HeaderSmall from '../text/HeaderSmall';
import Paragraph from '../text/Paragraph';

const data = [
  {
    id: 1,
    imgUrl: '/tomasz-rozanski.png',
    title: (
      <>
        <span className="text-primary mr-1">dr </span>Tomasz Różański
      </>
    ),
  },
  {
    id: 2,
    imgUrl: '/jolanta-nalewaj.png',
    title: (
      <>
        <span className="text-primary mr-1">dr </span> Jolanta Nalewaj - Nowak
      </>
    ),
  },
  {
    id: 3,
    imgUrl: '/karolina-banys.png',
    title: (
      <>
        <span className="text-primary mr-1">dr n. med.</span> Karolina Banyś
      </>
    ),
  },
  {
    id: 4,
    imgUrl: '/marta-spodzieja.png',
    title: <>Marta Spodzieja</>,
  },
  {
    id: 5,
    imgUrl: '/wioletta-chachotka.png',
    title: <>Wioleta Chachotka</>,
  },
  {
    id: 6,
    imgUrl: '/liroy.png',
    title: <>Piotr Liroy Marzec</>,
  },
  {
    id: 7,
    imgUrl: '/maciej-sagal.png',
    title: <>Maciej Sagal</>,
  },
  {
    id: 8,
    imgUrl: '/alina-grodzicka.png',
    title: <>Alina Grodzicka</>,
  },

  {
    id: 9,
    imgUrl: '/aleksandra-brek.png',
    title: <>Aleksandra Bręk</>,
  },
];

function Slide(props: { imgUrl: string; title: JSX.Element; id: number }) {
  return (
    <div
      className="min-w-[220px] max-w-[220px] p-4 rounded-[30px] border border-solid border-primary flex flex-col gap-4"
      key={props.id}
    >
      <img
        className="h-[200px] rounded-t-[30px]"
        src={props.imgUrl}
        alt="top image"
      />
      <HeaderSmall class="m-0">{props.title}</HeaderSmall>
    </div>
  );
}

function CarouselWithHeaderSection() {
  return (
    <section
      id="about"
      className="my-12 flex flex-col 2xl:flex-row min-h-[70vh]"
      style={createStyleForBackgroundImage('/hero-bg.svg', {
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        withDarkOverlay: false,
        backgroundRepeat: 'no-repeat',
      })}
    >
      <div
        className={`px-8 2xl:px-0 flex flex-col items-end justify-center py-12 2xl:w-[40%]`}
      >
        <div className="flex flex-col items-start justify-center gap-4 2xl:w-3/4">
          <HeaderBig class="m-0">
            <>
              Poznaj nasz
              <br />
              <span className="text-primary">zespół</span>
            </>
          </HeaderBig>
          <Paragraph>
            <>
              Jesteśmy miłośnikami zastosowań konopnych w celach najbardziej
              służących ludziom. Dziewięciu ekspertów zebrało się w jedno
              miejsce aby przekazać Ci tą cenną wiedzę
              <br />
              <br />
              <ul>
                <li>dr Tomasz Różański</li>
                <li>Piotr Liroy Marzec</li>
                <li>dr Jolanta Nalewaj - Nowak</li>
                <li>dr n. med. Karolina Banyś</li>
                <li>Wioleta Chachotka</li>
                <li>Anna Krawczak</li>
                <li>Alina Grodzicka</li>
                <li>Anita Kaczorowska</li>
                <li>Marta Spodzieja</li>
              </ul>
            </>
          </Paragraph>
        </div>
      </div>
      <div className="p-4 flex flex-col justify-center items-center 2xl:w-[60%] overflow-hidden">
        <div className="w-[90%]">
          <MultiItemCarousel
            data={data}
            class="gap-4"
            showIndicators
            elementWidth={270}
          >
            {data.map((item) => (
              <Slide {...item} />
            ))}
          </MultiItemCarousel>
        </div>
      </div>
    </section>
  );
}

export default CarouselWithHeaderSection;
