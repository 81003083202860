import { useEffect, useRef, useState } from 'react';
import CarouselArrow from './icon/CarouselArrow';

const MultiItemCarousel = (props: {
  data: any[];
  children: any;
  class?: string;
  arrowsClass?: string;
  autoScroll?: boolean;
  elementWidth?: number;
  showIndicators?: boolean;
}) => {
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef<HTMLDivElement | null>(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (carousel.current !== null) {
      if (props.elementWidth) {
        // If elementWidth is provided, scroll by element width
        const nextIndex = currentIndex + 1;
        const scrollDistance = props.elementWidth * nextIndex;
        if (scrollDistance <= maxScrollWidth.current) {
          setCurrentIndex(nextIndex);
        } else if (props.autoScroll) {
          // If at the end and autoScroll is enabled, loop back to the beginning
          setCurrentIndex(0);
        }
      } else {
        // If elementWidth is not provided, scroll by carousel width
        if (currentIndex < props.data.length - 1) {
          setCurrentIndex((prevState) => prevState + 1);
        } else if (props.autoScroll) {
          setCurrentIndex(0);
        }
      }
    }
  };

  useEffect(() => {
    if (props.autoScroll) {
      const intervalId = setInterval(() => {
        moveNext();
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [moveNext, props.autoScroll]);

  useEffect(() => {
    if (carousel.current !== null) {
      if (props.elementWidth) {
        carousel.current.scrollLeft = props.elementWidth * currentIndex;
      } else {
        carousel.current.scrollLeft =
          carousel.current.offsetWidth * currentIndex;
      }
    }
  }, [currentIndex, carousel, props.elementWidth]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  return (
    <div className="carousel flex flex-row items-center justify-center">
      {/* <CarouselArrow class={props.arrowsClass} isLeft onClick={movePrev} /> */}
      <div className="relative overflow-hidden flex flex-col lg:justify-center lg:items-center">
        <div
          ref={carousel}
          className={`carousel-container relative lg:w-[80%] flex overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0 ${props.class}`}
        >
          {props.children}
        </div>
        {props.showIndicators && (
          <div className="dot-indicators-container flex items-center self-center mt-4">
            {props.data.map((_, index) => (
              <div
                key={index}
                className={`rounded-full mx-1 ${
                  index === currentIndex
                    ? 'bg-primary w-4 h-4'
                    : 'bg-recommendedCard w-3 h-3'
                } cursor-pointer`}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        )}
      </div>
      {/* <CarouselArrow class={props.arrowsClass} onClick={moveNext} /> */}
    </div>
  );
};

export default MultiItemCarousel;
