import { defaultStyles } from '@/helper/defaultStyles';
import HeaderBig from '../text/HeaderBig';
import Paragraph from '../text/Paragraph';

const data = [
  {
    id: 1,
    title: <>Nie chcesz zostać w tyle wobec rewolucyjnej branży</>,
  },
  {
    id: 2,
    title: <>Chcesz zwiększyć swoje zarobki i status zawodowy</>,
  },
  {
    id: 3,
    title: (
      <>
        Chcesz mieć umiejętności do jeszcze lepszego zadbania o swoich klientów,
        a co najważniejsze - również swoich bliskich
      </>
    ),
  },
  {
    id: 4,
    title: <>TEN KURS JEST DLA CIEBIE!</>,
  },
];

function Item(props: { id: number; title: JSX.Element }) {
  return (
    <div
      key={props.id}
      className="flex flex-row items-center gap-4 border-2 border-solid border-black border-l-0 border-r-0 border-t-0 pb-2"
    >
      <div className="min-w-[48px] min-h-[48px] flex flex-row justify-center items-center bg-black rounded-full text-center">
        <Paragraph class="!font-[600] !text-primary">
          <>{props.id}</>
        </Paragraph>
      </div>
      <Paragraph class="!font-bold !text-black">{props.title}</Paragraph>
    </div>
  );
}

function PainsSection() {
  return (
    <section
      className={`${defaultStyles.paddingBig} py-12 flex flex-col-reverse xl:flex-row gap-12 justify-center items-center bg-primary`}
    >
      <img
        className="w-[70%] md:w-[60%] xl:w-1/2"
        src="/pains.png"
        alt="hero image"
      />
      <div className="flex flex-col gap-4">
        <HeaderBig>
          <>Jeżeli...</>
        </HeaderBig>
        {data.map((item) => (
          <Item {...item} />
        ))}
      </div>
    </section>
  );
}

export default PainsSection;
