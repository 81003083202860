import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import { defaultStyles } from '@/helper/defaultStyles';
import HeaderBig from '../text/HeaderBig';
import Paragraph from '../text/Paragraph';
import SignUpButton from './SignUpButton';

const checkmarks = [
  'Kurs ekspret konpny - kompleksowa wiedza o konopiach która zmieni Twoje życie na lepsze',
  'Certyfikowane potwierdzenie kompetencji',
  'Fakultatywny egzamin TÜV Rheinland potwierdzający kompetencje na szczeblu międzynarodowym',
  'Uniwersytecka ranga szkolenia',
  'Wykwalifikowana kadra - najlepsi i doświadczeni specjaliści',
  'Wygodna formuła - zajęcia online w weekendy',
  'Wiedza poparta praktyką',
];

function PlansSection() {
  return (
    <section
      className={`${defaultStyles.paddingBig} py-12 flex flex-col gap-8`}
    >
      <div className="flex flex-col items-center justify-center text-center gap-4">
        <HeaderBig class="my-0 text-primary">
          <>
            Zapisz się na kurs
            <br />
            <span className="text-black">już dziś!</span>
          </>
        </HeaderBig>
        <Paragraph class="xl:w-1/2">
          <>
            Nie praktykuj braku wiedzy, skorzystaj z możliwości pozyskania
            szansy do dołączenia ciągle rozwijającej się branży CBD!
          </>
        </Paragraph>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-8 rounded-[40px] px-8 py-12"
        style={createStyleForBackgroundImage('buy-img.png')}
      >
        <HeaderBig class="m-0">
          <>
            <span className="strike-through font-light">4 500,00 PLN</span>
            <br />2 450,00 PLN*
          </>
        </HeaderBig>
        <div className="h-[1px] bg-black w-full" />
        <div className="flex flex-col gap-4 justify-start items-start w-full">
          {checkmarks.map((item) => (
            <div className="flex flex-row gap-2">
              <img
                className="w-[24px] h-[24px]"
                src="/icon/checkmark-icon.svg"
                alt="checkmark icon"
              />
              <Paragraph class="!font-[500] !text-black">
                <>{item}</>
              </Paragraph>
            </div>
          ))}
        </div>
        <div className="h-[1px] bg-black w-full" />
        <SignUpButton secondary />
      </div>
    </section>
  );
}

export default PlansSection;
