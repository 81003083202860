import HeaderBig from '../text/HeaderBig';
import HeaderSmall from '../text/HeaderSmall';
import Paragraph from '../text/Paragraph';
import SignUpButton from './SignUpButton';

function HeroMainHeader() {
  return (
    <div className="flex flex-col xl:flex-row justify-center items-center gap-12">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <HeaderBig class="m-0 leading-[2.6rem]">
            <>
              Zyskaj uprawnienia
              <br />
              <span className="text-primary text-[27px]">
                eksperta konopnego w swojej branży
              </span>
            </>
          </HeaderBig>
          <img className="w-3/4" src="/green-line.svg" alt="line" />
        </div>
        <HeaderSmall>
          <>Start kursu 22 Marca</>
        </HeaderSmall>
        <Paragraph>
          <>
            Kurs jako wyjątkowa alternatywa dla tradycyjnych programów
            akademickich, zapewniająca dogłębne zrozumienie terapii konopnej bez
            konieczności wieloletnich studiów. Nasi uczestnicy otrzymują dostęp
            do specjalistycznej i obszernej bazy wiedzy, umożliwiającej
            profesjonalne doradztwo w jeszcze nie w pełni odkrytym obszarze
            suplementacji konopnej.
          </>
        </Paragraph>
        <SignUpButton class="w-[260px]" />
      </div>
      <img src="tablet.png" className="w-[80%] xl:w-1/2" alt="tablet image" />
    </div>
  );
}

export default HeroMainHeader;
