import { useState } from 'react';

import HeaderSmall from '../text/HeaderSmall';
import Paragraph from '../text/Paragraph';

function FaqItem(props: { id: number; title: string; desc: string }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className={`flex flex-col justify-center items-center gap-8 w-full border border-solid border-grayBorder border-l-0 border-r-0 border-t-0 p-4 ${
        isActive && 'bg-secondaryLight'
      }`}
      key={props.id}
    >
      <div className="flex flex-row gap-8 items-center justify-between w-full">
        <div className="flex flex-row gap-8 items-center">
          <div className="text-textLight text-[48px] font-bold">
            0{props.id}
          </div>
          <HeaderSmall class="m-0">
            <>{props.title}</>
          </HeaderSmall>
        </div>
        <div
          className={`min-w-[72px] min-h-[72px] rounded-full flex flex-row justify-center items-center cursor-pointer transition-all duration-300 ${
            isActive ? 'bg-secondary' : 'bg-primary'
          }`}
          onClick={() => setIsActive(!isActive)}
        >
          <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${
              isActive ? 'rotate-45' : ''
            } transition-all duration-300`}
          >
            <g clipPath="url(#clip0_13_1955)">
              <path
                d="M20.707 30.6074L20.707 20.7079L10.8075 20.7079"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M30.2529 21.0615L20.3534 21.0615L20.3534 11.162"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_13_1955">
                <rect
                  width="28"
                  height="28"
                  fill="white"
                  transform="translate(20.707 40.5068) rotate(-135)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      {isActive && (
        <div className="p-4">
          <Paragraph>
            <>{props.desc}</>
          </Paragraph>
        </div>
      )}
    </div>
  );
}

export default FaqItem;
