import { defaultStyles } from '@/helper/defaultStyles';
import Button from '../Button';
import HeaderBig from '../text/HeaderBig';
import Paragraph from '../text/Paragraph';
import FaqItem from './FaqItem';

const data = [
  {
    id: 1,
    title: 'Czym zajmuje się Terapeuta Konopny?',
    desc: 'Terapeuta Konopny to specjalista posiadający wiedzę na temat stosowania produktów konopnych, w tym CBD, w celach terapeutycznych i suplementacyjnych. Osoba na tym stanowisku doradza pacjentom najodpowiedniejsze metody suplementacji konopnej, mając na uwadze ich indywidualne potrzeby i najnowsze badania w branży.',
  },
  {
    id: 2,
    title: 'Dla kogo przeznaczony jest kurs Terapeuta Konopny?',
    desc: 'Kurs jest skierowany do osób zainteresowanych branżą i terapiami konopnymi, zarówno początkujących, jak i tych posiadających już pewną wiedzę. Idealny dla osób pragnących uzyskać certyfikat Terapeuty Konopnego, jak również dla tych, którzy chcą poszerzyć swoje kompetencje w zakresie terapii i suplementacji konopnej.',
  },
  {
    id: 3,
    title: 'Jakie są wymagania wstępne do wzięcia udziału w kursie?',
    desc: 'Nie ma szczególnych wymagań wstępnych do uczestnictwa w kursie. Jest on otwarty dla wszystkich osób zainteresowanych zdobyciem wiedzy na temat CBD i terapii konopnej.',
  },
];

function FaqSection() {
  return (
    <section
      className={`${defaultStyles.paddingBig} py-12 flex flex-col gap-8`}
    >
      <div className="flex flex-col items-center justify-center text-center gap-4">
        <HeaderBig class="my-0 text-primary">
          <>
            Najczęściej <span className="text-black">zadawane pytania</span>
          </>
        </HeaderBig>
        <Paragraph class="xl:w-1/2">
          <>Wszystko, co powinienieś wiedzieć o naszych usługach.</>
        </Paragraph>
      </div>
      <div className="flex flex-col justify-center items-center">
        {data.map((item) => (
          <FaqItem {...item} />
        ))}
      </div>
      <Button
        label="Dowiedz się więcej"
        class="w-[300px] self-center"
        onClick={() => window.location.assign('/faq')}
      />
    </section>
  );
}

export default FaqSection;
