import { defaultStyles } from '@/helper/defaultStyles';
import HeaderSmall from '../text/HeaderSmall';
import Paragraph from '../text/Paragraph';
import HeaderBig from '../text/HeaderBig';

const data = [
  {
    id: 1,
    imageUrl: '/icon/movie-icon.svg',
    title: 'Filmy szkoleniowe',
    desc: 'Ponad 24 godzin materiałów.',
  },
  {
    id: 2,
    imageUrl: '/icon/group-icon.svg',
    title: 'Case study',
    desc: 'Rozwiązania terapeutyczne na podstawie przypadków pacjentów potwierdzonych badaniami klinicznymi.',
  },
  {
    id: 3,
    imageUrl: '/icon/filmClip-icon.svg',
    title: 'Materiały dodatkowe',
    desc: 'Prezentacje i materiały szkoleniowe',
  },
  {
    id: 4,
    imageUrl: '/icon/247-icon.svg',
    title: 'Dostęp 24/7',
    desc: 'Nieprzerwany dostęp z każdego miejsca i urządzenia z Internetem, dzięki czemu uczysz się kiedy i jak chcesz.',
  },
  {
    id: 5,
    imageUrl: '/icon/medal-icon.svg',
    title: 'Certyfikat Ukończenia',
    desc: 'Poświadczający udział w kursie.',
  },
];

function Item(props: {
  id: number;
  imageUrl: string;
  title: string;
  desc: string;
}) {
  return (
    <div
      className="flex flex-row justify-start items-center gap-4 px-4 h-[94px]"
      key={props.id}
    >
      <div className="flex flex-col justify-center items-center bg-primary min-w-[78px] max-w-[78px] min-h-[78px] max-h-[78px] shadow-bottom rounded-full">
        <img className="w-[50px] h-[50px]" src={props.imageUrl} alt="icon" />
      </div>
      <div className="flex flex-col gap-2">
        <HeaderSmall class="m-0">
          <>{props.title}</>
        </HeaderSmall>
        <Paragraph>
          <>{props.desc}</>
        </Paragraph>
      </div>
    </div>
  );
}

function ProfitsSection() {
  return (
    <section
      className={`${defaultStyles.paddingBig} py-12 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-12 md:gap-8 justify-center items-center`}
    >
      <HeaderBig class="m-0">
        <>
          Dołączając do <span className="text-primary">nas zyskasz:</span>
        </>
      </HeaderBig>
      {data.map((item) => (
        <Item {...item} />
      ))}
    </section>
  );
}

export default ProfitsSection;
