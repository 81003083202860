import { defaultStyles } from '@/helper/defaultStyles';
import HeaderBig from '../text/HeaderBig';
import HeaderSmall from '../text/HeaderSmall';
import Paragraph from '../text/Paragraph';
import Button from '../Button';
import { useState } from 'react';

const data = [
  {
    id: 0,
    place: 'online',
    teacher: 'Piotr Liroy - Marzec',
    title: <>Preambuła - W Kielcach wszystko się zaczęło...</>,
    hours: '18:00 - 19:30',
    newDay: 'DZIEŃ PIERWSZY 22.03',
  },
  {
    id: 1,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Historia - najważniejsze fakty historyczne o konopiach</>,
    hours: '19:30 - 22:00',
  },
  {
    id: 2,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Układ Endokannabinoidowy</>,
    hours: '09:00 - 10:00',
    newDay: 'DZIEŃ DRUGI 23.03',
  },
  {
    id: 3,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Homeostaza</>,
    hours: '10:00 - 11:00',
  },
  {
    id: 4,
    place: 'online',
    teacher: 'terapeuta, naturopata, Wioleta Chachotka',
    title: <>Anatomia rośliny cannabis, od korzeni po wierzchołek</>,
    hours: '11:00 - 12:00',
  },
  {
    id: 5,
    place: 'online',
    teacher: 'terapeuta, naturopata, Wioleta Chachotka',
    title: <>Kannabinoidy</>,
    hours: '12:00 - 13:00',
  },
  {
    id: 6,
    place: 'online',
    teacher: 'terapeuta, naturopata, Wioleta Chachotka',
    title: <>Terpeny</>,
    hours: '13:00 - 14:00',
  },
  {
    id: 7,
    place: 'online',
    teacher: 'terapeuta, naturopata, Wioleta Chachotka',
    title: <>Flawonoidy</>,
    hours: '14:00 - 15:00',
  },
  {
    id: 8,
    place: 'online',
    teacher: 'dr Karolina Banyś',
    title: <>Interakcje kannabinoidów z lekami, żywnością i suplementami</>,
    hours: '09:00 - 10:00',
    newDay: 'DZIEŃ TRZECI 24.03',
  },
  {
    id: 9,
    place: 'online',
    teacher: 'dr Karolina Banyś',
    title: <>Metabolizm kannabinoidów</>,
    hours: '10:00 - 11:00',
  },
  {
    id: 10,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Sieć systemów i receptorów naszego organizmu</>,
    hours: '11:00 - 12:00',
  },
  {
    id: 11,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: (
      <>
        Full spectrum wiedzy o dawkowaniu i formach suplementacji składników
        aktywnych cannabis
      </>
    ),
    hours: '12:00 - 13:00',
  },
  {
    id: 12,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Metody ekstrakcji</>,
    hours: '13:00 - 14:00',
  },
  {
    id: 13,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Kannabinoidy w weterynarii</>,
    hours: '18:00 - 19:00',
    newDay: 'DZIEŃ CZWARTY 12.04',
  },
  {
    id: 14,
    place: 'online',
    teacher: 'mgr. Aleksandra Bręk',
    title: <>Social Media. Jak prowadzić content związany z konopiami</>,
    hours: '19:00 - 21:00',
  },
  {
    id: 15,
    place: 'online',
    teacher: 'dr Jolanta Nalewaj Nowak',
    title: <>Zasady zdrowego stylu życia</>,
    hours: '09:00 - 11:00',
    newDay: 'DZIEŃ PIĄTY 13.04',
  },
  {
    id: 16,
    place: 'online',
    teacher: 'mgr. Anna Krawczak, audytor farmaceutyczny',
    title: <>Aspekty jakościowe i prawne, certyfikacja i analiza jakościowa</>,
    hours: '11:00 - 12:00',
  },
  {
    id: 17,
    place: 'online',
    teacher: 'mgr. Anna Krawczak, audytor farmaceutyczny',
    title: <>Dlaczego jakość ma znaczenie i jak ją zweryfikować?</>,
    hours: '12:00 - 14:00',
  },
  {
    id: 18,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Przeciwwskazania. Jak suplementować przy przeciwwskazaniach?</>,
    hours: '09:00 - 10:00',
    newDay: 'DZIEŃ SZÓSTY 14.04',
  },
  {
    id: 19,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Choroby mitochondrialne - wprowadzenie do terapii konopnych</>,
    hours: '10:00 - 11:00',
  },
  {
    id: 20,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Choroby dziecięce - wprowadzenie do terapii konopnych</>,
    hours: '11:00 - 12:00',
  },
  {
    id: 21,
    place: 'online',
    teacher: 'dr Jolanta Nalewaj Nowak',
    title: (
      <>
        Dietetyka - jedzenie, które leczy. Na czym polega żywienie terapetyczne?
      </>
    ),
    hours: '12:00 - 13:00',
  },
  {
    id: 22,
    place: 'online',
    teacher: 'dr Jolanta Nalewaj Nowak',
    title: (
      <>Czy odpowiednie składniki pożywienia mogą zagwarantować zdrowie?</>
    ),
    hours: '13:00 - 14:00',
  },
  {
    id: 23,
    place: 'online',
    teacher: 'dr Anita Kaczorowska',
    title: <>Mechanizm działania medycznej marihuany w leczeniu bólu.</>,
    hours: '18:00 - 19:00',
    newDay: 'DZIEŃ SIÓDMY 26.04',
  },
  {
    id: 24,
    place: 'online',
    teacher: 'dr Anita Kaczorowska',
    title: <>Medyczna marihuana w chorobach reumatycznych</>,
    hours: '19:00 - 20:00',
  },
  {
    id: 25,
    place: 'online',
    teacher: 'dr Anita Kaczorowska',
    title: <>Medyczna marihuana w chorobach onkologicznych</>,
    hours: '20:00 - 21:00',
  },
  {
    id: 26,
    place: 'online',
    teacher: 'Marta Spodzieja',
    title: <>Kannabinoidy w kosmetologii</>,
    hours: '09:00 - 10:00',
    newDay: 'DZIEŃ ÓSMY 27.04',
  },
  {
    id: 27,
    place: 'online',
    teacher: 'Marta Spodzieja',
    title: <>Pozostałe substancje konopi w kosmetologii</>,
    hours: '10:00 - 11:00',
  },
  {
    id: 28,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: (
      <>Wpływ w kannabinoidów na skórę. Problematyka ran ciężko gojących</>
    ),
    hours: '11:00 - 12:00',
  },
  {
    id: 29,
    place: 'online',
    teacher: 'dr Karolina Banyś',
    title: (
      <>Wpływ CBD i THC na zespół metaboliczny i zaburzenia metaboliczne</>
    ),
    hours: '12:00 - 14:00',
  },
  {
    id: 30,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Choroby neurodegeneracyjnewprowadzenie do terapii konopnych</>,
    hours: '09:00 - 10:00',
    newDay: 'DZIEŃ DZIEWIĄTY 28.04',
  },
  {
    id: 31,
    place: 'online',
    teacher: 'dr Tomasz Różański',
    title: <>Choroby onkologicznewprowadzenie do terapii konopnych</>,
    hours: '10:00 - 11:00',
  },
  {
    id: 32,
    place: 'online',
    teacher: 'dr Karolina Banyś',
    title: <>Kannabinoidy w problemach niepłodności i seksualności</>,
    hours: '11:00 - 12:00',
  },
  {
    id: 33,
    place: 'online',
    teacher: 'dr Karolina Banyś',
    title: (
      <>Minimalizowanie interakcji między fitokannabinoidami a farmaceutykami</>
    ),
    hours: '12:00 - 13:00',
  },
  {
    id: 34,
    place: 'stacjonarnie',
    teacher: 'Alina Grodzicka',
    title: <>Kompetencje eksperta konopnego w biznesie</>,
    hours: '10:00 - 11:00',
    newDay: 'DZIEŃ DZIESIĄTY 11.05',
  },
  {
    id: 35,
    place: 'stacjonarnie',
    teacher: 'Alina Grodzicka',
    title: (
      <>
        Czym jest dyrektywa unijna work-life balance i jak zmieni się branża
        konopna po wprowadzeniu jej w życie
      </>
    ),
    hours: '11:00 - 12:00',
  },
  {
    id: 36,
    place: 'stacjonarnie',
    teacher: 'Alina Grodzicka',
    title: (
      <>
        Pozyskiwanie środków na rozwój i funkcjonowanie gabinetów
        terapeutycznych
      </>
    ),
    hours: '12:00 - 13:00',
  },
  {
    id: 37,
    place: 'stacjonarnie',
    teacher: 'Alina Grodzicka',
    title: <>Jak prowadzić konsultacje konopne z dofinansowaniem UE?</>,
    hours: '13:00 - 14:00',
  },
  {
    id: 38,
    place: 'stacjonarnie',
    teacher: 'Alina Grodzicka',
    title: (
      <>
        Egzamin TUV Rheinland potwierdzający kompetencje na szczeblu
        międzynarodowym
      </>
    ),
    hours: '14:30 - 15:00',
  },
];

function Item(props: {
  id: number;
  place: string;
  teacher: string;
  hours?: string;
  newDay?: string;
  title: JSX.Element;
}) {
  return (
    <>
      {props.newDay && (
        <div className="font-bold text-[24px] text-primary p-4">
          {props.newDay}
        </div>
      )}
      <div
        key={props.id}
        className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-8 border border-solid border-grayBorder border-l-0 border-r-0 border-t-0"
      >
        <div className="flex flex-row gap-4 items-center lg:w-1/3">
          <div className="shadow-bottom min-w-[78px] min-h-[78px] max-w-[78px] max-h-[78px] flex flex-row justify-center items-center bg-primary rounded-full text-center">
            <Paragraph class="!font-[600] !text-black">
              <>{props.id + 1}</>
            </Paragraph>
          </div>
          <div className="flex flex-col gap-2">
            <HeaderSmall class="my-0 !font-bold !text-[16px] !text-black">
              {props.title}
            </HeaderSmall>
            <div className="font-bold text-[24px] text-primary">
              {props.hours}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 border border-solid border-r-0 border-t-0 border-b-0 border-[#D2D2D2] pl-4 lg:w-1/4">
          <Paragraph class="!font-bold uppercase">
            <>miejsce odbywania się zajęć</>
          </Paragraph>
          <Paragraph class="!font-bold uppercase !text-black">
            <>{props.place}</>
          </Paragraph>
        </div>
        <div className="flex flex-col gap-2 border border-solid border-r-0 border-t-0 border-b-0 border-[#D2D2D2] pl-4 lg:w-1/4">
          <Paragraph class="!font-bold uppercase">
            <>prowadzący zajęcia</>
          </Paragraph>
          <Paragraph class="!font-bold uppercase !text-black">
            <>{props.teacher}</>
          </Paragraph>
        </div>
      </div>
    </>
  );
}

function LessonsSection() {
  const [showMore, setShowMore] = useState(false);

  return (
    <section
      className={`${defaultStyles.paddingBig} py-12 flex flex-col gap-8 bg-secondaryLight`}
      id="schedule"
    >
      <div className="flex flex-col items-center justify-center text-center gap-4">
        <HeaderBig class="my-0 text-primary">
          <>
            Rozkład <span className="text-black">zajęć</span>
          </>
        </HeaderBig>
      </div>
      <div className="flex flex-col gap-4">
        {data.slice(0, showMore ? 39 : 4).map((item) => (
          <Item {...item} />
        ))}
      </div>
      <Button
        label={showMore ? 'Zobacz mniej' : 'Zobacz więcej'}
        class="w-[260px] self-center"
        onClick={() => setShowMore(!showMore)}
      />
    </section>
  );
}

export default LessonsSection;
