import HeaderSmall from '../text/HeaderSmall';
import Paragraph from '../text/Paragraph';

const data = [
  {
    imageUrl: '/icon/brain-icon.svg',
    title: <>Przełomowa wiedza</>,
    desc: (
      <>
        Zyskaj nie dostępną dla innych osób wiedzę na temat konopi, która
        zmienia życia
      </>
    ),
  },
  {
    imageUrl: '/icon/money-icon.svg',
    title: <>Oszczędność pieniędzy</>,
    desc: (
      <>
        Łączna wartość czasu naszych ekspertów wyniosłaby przynajmniej 10 000zł,
        z nami jest to znacznie tańsze
      </>
    ),
  },
  {
    imageUrl: '/icon/cert-icon.png',
    title: <>Pożądanie ludzi</>,
    desc: (
      <>
        W aktualnych czasach ludzie coraz częściej szukają alternatywnych dla
        farmakologii rozwiązań leczenia, ludzie potrzebują ekspertów w tej
        dziedzinie
      </>
    ),
  },
];

function Item(props: {
  id: number;
  imageUrl: string;
  title: JSX.Element;
  desc: JSX.Element;
}) {
  return (
    <div
      className="flex flex-col gap-4 items-center justify-center text-center"
      key={props.id}
    >
      <div className="w-[88px] h-[88px] flex flex-row justify-center items-center rounded-full bg-primary">
        <img
          src={props.imageUrl}
          className="w-[55px] h-[55px]"
          alt="hero icon"
        />
      </div>
      <HeaderSmall class="text-default">{props.title}</HeaderSmall>
      <Paragraph class="!text-textLight">{props.desc}</Paragraph>
    </div>
  );
}

function HeroItemsGroup() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-start gap-4 p-8 bg-defaultDark rounded-[30px]">
      {data.map((item, index) => (
        <Item {...item} id={index} />
      ))}
    </div>
  );
}

export default HeroItemsGroup;
