import { defaultStyles } from '@/helper/defaultStyles';
import HeaderBig from '../text/HeaderBig';
import Paragraph from '../text/Paragraph';

function GraphSection() {
  return (
    <section
      className={`${defaultStyles.paddingBig} py-12 flex flex-col xl:flex-row gap-12 justify-center items-center`}
      id="course-perks"
    >
      <div className="flex flex-col gap-4 xl:w-1/2">
        <HeaderBig>
          <>Dlaczego warto pozyskać wiedzę na temat CBD?</>
        </HeaderBig>
        <Paragraph>
          <>
            W 2022 roku wartość rynku suplementów diety w Polsce, w tym
            produktów CBD, osiągnęła około{' '}
            <span className="!font-bold !text-primary">6,9 mld zł</span>, co
            oznacza wzrost o blisko{' '}
            <span className="!font-bold !text-primary">12%</span> rok do roku.
            Prognozy wskazują, że trend wzrostowy na tym rynku utrzyma się
            również w najbliższych latach, co wiąże się m.in. ze wzrostem liczby
            przypadków infekcji sezonowych. CBD, jako jeden z kluczowych
            segmentów tego rynku, cieszy się rosnącą popularnością, co
            potwierdzają także obserwacje producentów i sprzedawców, wskazujące
            na duże zapotrzebowanie na produkty wysokiej jakości, zwłaszcza te o
            przejrzystym składzie i odpowiedniej standaryzacji. Pomimo braku
            bezpośrednich danych finansowych wyłącznie dla rynku CBD w Polsce,
            całościowy wzrost wartości rynku suplementów diety, w tym również
            produktów CBD, może dostarczać pewnego obrazu na temat trendów i
            perspektyw dla tej kategorii produktów.
          </>
        </Paragraph>
      </div>
      <img
        className="w-[70%] md:w-[60%] xl:w-1/2"
        src="/graph-section-img.png"
        alt="graph image"
      />
    </section>
  );
}

export default GraphSection;
