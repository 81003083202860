import { FunctionComponent } from 'react';

import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import { defaultStyles } from '@/helper/defaultStyles';
import HeroMainHeader from './HeroMainHeader';
import HeroItemsGroup from './HeroItemsGroup';

const HeroSection: FunctionComponent = () => {
  return (
    <section
      className={`${defaultStyles.paddingBig} flex flex-col gap-12 pt-24 pb-12 min-h-[70vh]`}
      style={createStyleForBackgroundImage('/hero-bg.svg', {
        backgroundSize: 'contain',
        backgroundPosition: 'right',
        withDarkOverlay: false,
        backgroundRepeat: 'no-repeat',
      })}
    >
      <HeroMainHeader />
      <HeroItemsGroup />
    </section>
  );
};

export default HeroSection;
