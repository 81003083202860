import { defaultStyles } from '@/helper/defaultStyles';
import HeaderBig from '../text/HeaderBig';
import Paragraph from '../text/Paragraph';

function Item(props: { id: number; class?: string; videoUrl?: string }) {
  return (
    <div className={`${props.class} flex flex-col gap-4`}>
      {props.videoUrl && (
        <video controls width="100%" className="rounded-[30px]">
          <source src={props.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

function RecommendedSection() {
  return (
    <section
      className={`${defaultStyles.paddingBig} py-12 flex flex-col gap-8`}
      id="reviews"
    >
      <div className="flex flex-col items-center justify-center text-center gap-4">
        <HeaderBig class="my-0 text-primary">
          <>Polecają nas</>
        </HeaderBig>
        <Paragraph class="xl:w-1/2">
          <>Poznaj opinie naszych kursantów</>
        </Paragraph>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Item id={1} videoUrl="/video/video-1.mov" />
        <Item id={2} videoUrl="/video/video-2.mov" />
        <Item id={3} videoUrl="/video/video-3.mov" />
      </div>
    </section>
  );
}

export default RecommendedSection;
