import { FunctionComponent } from 'react';

import BannerCourseSection from '@/components/home/BannerCourseSection';
import CarouselWithHeaderSection from '@/components/home/CarouselWithHeaderSection';
import FaqSection from '@/components/home/FaqSection';
import GraphSection from '@/components/home/GraphSection';
import LessonsSection from '@/components/home/LessonsSection';
import PainsSection from '@/components/home/PainsSection';
import PlansSection from '@/components/home/PlansSection';
import ProfitsSection from '@/components/home/ProfitsSection';
import RecommendedSection from '@/components/home/RecommendedSection';
import Head from 'next/head';
import HeroSection from '../components/home/HeroSection';

const Home: FunctionComponent = () => {
  return (
    <div className="w-full overflow-hidden">
      <Head>
        <title>Kurs Akademicki</title>
        <meta name="description" content="Strona główna kursu akademickiego" />
      </Head>
      <HeroSection />
      <PainsSection />
      <BannerCourseSection />
      <GraphSection />
      <ProfitsSection />
      {/* <CourseFragmentsSection /> */}
      <RecommendedSection />
      <LessonsSection />
      <PlansSection />
      {/* <BenefitsSection /> */}
      {/* <AboutSection /> */}
      {/* <WarrantySection /> */}
      <CarouselWithHeaderSection />
      <FaqSection />
    </div>
  );
};

export default Home;
