import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import { defaultStyles } from '@/helper/defaultStyles';
import HeaderSmall from '../text/HeaderSmall';

const data = [
  {
    id: 1,
    title: 'Branża medyczna i wellness',
    imageUrl: '/icon/stethoscope-icon.svg',
  },
  {
    id: 2,
    title: 'Branża rolnicza',
    imageUrl: '/icon/farmer-icon.svg',
  },
  {
    id: 3,
    title: 'Branża produkcyjna',
    imageUrl: '/icon/production-icon.svg',
  },
  {
    id: 4,
    title: 'Branża prawnicza i regulacyjna',
    imageUrl: '/icon/lawyer-icon.svg',
  },
  {
    id: 5,
    title: 'Branża edukacyjna i szkoleniowa',
    imageUrl: '/icon/education-icon.svg',
  },
  {
    id: 6,
    title: 'Branża detaliczna i dystrybucyjna',
    imageUrl: '/icon/shopping-icon.svg',
  },
];

function Item(props: { id: number; imageUrl: string; title: string }) {
  return (
    <div
      className="bg-default rounded-[66px] flex flex-row justify-start items-center gap-4 px-4 shadow-md h-[94px]"
      key={props.id}
    >
      <div className="flex flex-col justify-center items-center bg-primary min-w-[72px] max-w-[72px] min-h-[72px] max-h-[72px] shadow-bottom rounded-full">
        <img className="w-[40px] h-[40px]" src={props.imageUrl} alt="icon" />
      </div>
      <HeaderSmall>
        <>{props.title}</>
      </HeaderSmall>
    </div>
  );
}

function BannerCourseSection() {
  return (
    <section
      className={`${defaultStyles.paddingBig} pb-12 flex flex-col justify-end items-center min-h-[70vh]`}
      style={createStyleForBackgroundImage('/course-section.svg', {
        withDarkOverlay: false,
        backgroundPosition: 'top',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-64 md:mt-0">
        {data.map((item) => (
          <Item {...item} />
        ))}
      </div>
    </section>
  );
}

export default BannerCourseSection;
